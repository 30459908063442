import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const NasiPsi = () => {
  return (
    <Layout>
      <Seo title="O nás" />
      <h1 className="post-title">O nás</h1>
      <p>
        Chráněná chovatelská stanice FCI bernských salašnických psů Ze Mlýna Svaté Anny byla založena v roce 2021 speciálně pro naši fenku Argu. Máme v plánu chovat spíše aktivnější a sportovně založené berňáčky. Z prvního vrhu bychom si rádi ponechali jednu fenku, aby dále pokračovala v rodové linii.
      </p>
    </Layout>
  )
}

export default NasiPsi
